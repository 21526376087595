/* 
#F9FBE7
#F0EDD4
#ECCDB4
#FEA1A1 */

/* Background color: Light, soothing shade of pastel pink (#E3B4B4)
Button colors: Deep burgundy red (#B5187E) with gold (#CDA44C) accent for hover state
Menu colors: Dark gray (#333333) with gold (#CDA44C) accent for hover state
Footer: Peach (#FFDAB9) with dark gray (#333333) text
Font colors and sizes: 
- Headings/titles: Deep burgundy red (#B5187E) with font size of 36px (or larger)
- Subheadings: Gold (#CDA44C) with font size of 24px
- Normal text: Dark gray (#333333) with font size of 18px */

/* App Background Color: #FDF6E9 */
.color{
	
	background-color: #F9FBE7;
	background-color: #F0EDD4;
	background-color: #ECCDB4;
	background-color: #FEA1A1;
	background-color: #E06D6D;
}

h2,h3,h4{
	font-family: 'Roboto Slab', serif;
}

/* For mobile devices */
@media only screen and (max-width: 768px) {
	.footer {
	  width: 90%;
	}
	.Noha {
		max-width:50% !important;
	}
	.div2 {
		flex-wrap: wrap;
		margin-top: 10px;
		padding: 20px;
		align-items: center;
		text-align: center;
	
	  }
	  .div2 > div {
		width: 100%;
		margin: 10px 0;
		/* padding: 10px */
	  }
	  .div2 > div:last-child {
		margin-bottom: 0;
	  }
	  /* .div2-l {
		width: 100% !important;
		padding: 10px;
	  }
	  .div2-r {
		width: 100% !important;
	  }
	  .telegram_button {
		width: 100% !important;
		margin-bottom: 10px !important;
	} */
	.centerMenu {
		max-width: 90%;
		align-items: center;
		margin: auto;
		display: inline-block;
	  }
	header{
		background-color: #333333;
		padding-bottom: 15px;
	}
	.s_n {
		color: #CDA44C;
	}

	.star_r {
		display: none;
	  }
	.star_l {
		display: none;
	  }

	  .social_b{
		padding: 0.5rem 2rem;
		display: flex;
		grid-template-columns: auto minmax(0px, 1fr) auto;
		gap: 8px;
		-webkit-box-align: center;
		align-items: center;
		overflow: hidden;
		margin: auto;
		/* background-color: #B981E3; */
		background-color: #D99CEB;
		color: #333333;
		font-weight: 500;
		font-size: 15px;
		border: none;
		border-radius: 20px;
		margin-bottom: 10px;
		width:70%;
		cursor: pointer;
		justify-content: center;
		/* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	  }

  }
  
  /* For tablet devices */
  @media only screen and (min-width: 600px) and (max-width: 992px) {
	.footer {
	  width: 90%;
	}
	.centerMenu {
	  max-width: 90%;
	  align-items: center;
	  margin: auto;
	  
	}
	.centerMain {
	  max-width: 90%;
	}

	.div2 {
		flex-wrap: wrap;
		margin-top: 10px;
		padding: 20px;
		align-items: center;
		text-align: center;
	
	  }
	  .div2 > div {
		width: 100%;
		margin: 10px 0;
		/* padding: 10px */
	  }
	  .div2 > div:last-child {
		margin-bottom: 0;
	  }
	  

  }
  
  @media only screen and (min-width: 768px) {
  .social_b{
	display: none;
  }
  }


  /* For desktop devices */
  @media only screen and (min-width: 1200px) {
	.footer {
	  width: 1200px;
	}
	header{
		width:1200px;
		margin: auto;
	}
	.centerMenu {
	  max-width: 1200px;
	}
	.centerMain {
	  max-width: 1200px;
	}

  }
  
  /* Common styles for all devices */
  .App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color:#FDF6E9;
	text-align: center;
	color: #333333;
	font-size: 15px;
	font-family: 'Roboto Slab', serif;
  }
  
  .App-main {
	flex: 1;
	overflow: auto;
	padding: 0;
  }
  
  html, body {
	height: 100%;
  }
  
  div#root {
	height: 100%;
  }
  
  .footer {
	text-align: center;
	height: 60px;
	color: #CDA44C;
	margin: 0 auto;
	padding: 10px 10px 20px 10px;
	font-size: 14px;
	background-color: #333333;
	border-radius: 15px 15px 0 0 ;
  }
  .footer a {
	color: #333333;
	text-decoration: none;
  }
  .footer a:hover {
	color: #49A6C3;
  }
  
  .s_n {
	display: block;
	margin: 0 auto;
	max-height: 30px;
	margin-top: 15px;
	margin-bottom: 15px;
	font-family: 'Roboto Slab', serif;
	
  }

  .h_top{
	display: flex;
	justify-content: center;
	align-items: center;
  }

  .star_r {
	max-width: 75px;
	margin-right: 25px;
  }

  .star_l {
	max-width: 75px;
	margin-left: 25px;
  }

.h_text{
	flex-grow: 1; /* Allow the text to expand and take available space */
	text-align: center;
  }

.centerMenu {
	margin-top: 15px;
	margin: 0 auto;
  }

  #menu {
	background-color: #333333;
	height: 32px;
	padding-top: 5px;
	padding-bottom: 5px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	direction: rtl;
	unicode-bidi: bidi-override;
	box-shadow: 0 0 10px rgba(205, 164, 76, 0.5);
  }
  
  #menu ul, #menu li {
	margin: 0 auto;
	padding: 0;
	list-style: none
  }
  
  #menu ul {
	width: 100%;
  }
  
  #menu li {
	float: right;
	display: inline;
	position: relative;
	margin-right: 10px;
  }
  
  #menu a {
	display: block;
	line-height: 22px;
	padding: 5px 15px;
	text-decoration: none;
	color: #CDA44C;
	font-size: 16px;
  }
  

  #menu li a:hover {
	border-radius: 10px;
	background-color: #CDA44C;
 	color: white;
  	transition: background-color 0.3s ease-in-out;
  }

  .mIcon{
		display: flex !important;
		align-items: center !important;
		margin-left:10px;
		gap: 8px; /* Adjust the value as needed for the desired padding */
  }
  .mIcon p{
	margin: 0;
  }

  .soc{
	position: relative;
	float: left !important;
  }

  .signin {
	float: left !important;
	position: relative !important;
  }
  
  .signin button {
	color: #333333 !important;
	border: none;
	font-size: 14px;
	font-weight: 600;
	padding: 8px 20px;
	position: relative;
	overflow: hidden;
	background-color: #CDA44C;
	border-radius: 10px;
	cursor: pointer;
	transition: box-shadow 0.3s ease-in-out;
  }
  
  .signin button::before {
	content: "";
	position: absolute;
	top: 0;
	left: -100%;
	width: 100%;
	height: 100%;
	background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
	z-index: 2;
	transition: left 0.8s ease-in-out;
  }
  
  .signin button:hover::before {
	left: 100%;
  }
  
  .signin button:hover {
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  }

  .language-menu {
	position: relative;
	 z-index: 5;
	 float: left !important;
  }

 li.language-menu {
	 margin-left: 15px !important;
  }
  
  .language-menu ul {
	position: absolute;
	top: 100%;
	left: 0;
	display: none;
	background-color: #fff;
	box-shadow: 0 2px 2px rgba(0,0,0,0.1);
	border-radius: 10px;
	padding: 5px 0;
  }
  
  .language-menu:hover ul {
	display: block;
  }
  
  .language-menu a {
	position: relative;
  }
  
  .caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-right: 10px;
	vertical-align: middle;
	border-top: 4px solid;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
  }
  .language-menu ul li {
	display: block;
	width: 100%;
	margin-left: 15px;
  }
 
  .sub_menu li{
	text-align: center !important;
	margin-right: 0px !important;
  }
	.sub_menu li a {
		margin-right: 0px  !important;
	}
  
  .centerMain {
	border-radius: 10px;
	padding: 0px !important;
  }
  
  .div1 {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
	justify-content: center;
    align-items: center;

  }
  .div1-l {
	width: 100%;
	display: block;
	justify-content: center;
	align-items: center;
	padding-top: 5px;
  }

  .div_cont{
	display: block;
	width: 100%;
  }

  .div1-r {
	border-radius: 10px;
  }

.div_txt {
	align-items: left;
	text-align: left;
	font-weight: 400;
	font-size: 15px;
	line-height: 25px;
}

.Nana {
	max-height:200px;
	border-radius: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.div2 {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	margin-top: 10px;
	text-align: center;
	justify-content: center;
  }
  .div2-l {
	width: 50%;
	align-items: center;
	text-align: center;
  }
  .div2-r {
	width: 50%;
  }

  .tel_icon{
	margin-right: 10px;
  }

 
  .telegram_button > * {
	display: block;
	text-align: center;
  }

.div3 {
--bs-gutter-x: 1.5rem;
--bs-gutter-y: 0;
flex-wrap: wrap;
margin-top: 10px;
padding: 25px;
align-items: left;
text-align: left;
border-radius: 10px;

}

  .div2-1 {
	width: 20%;
	margin: 10px;
	/* padding: 10px; */
	border: 1px solid #CDA44C;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(205, 164, 76, 0.5);
  }

  .div2-1 > p{
	padding: 5px;
  }

  @media (max-width: 768px) {
	.div2 {
	  display: block;
	  margin: 5px;
	  padding:5px
	}
	.div2 > div {
	  width: 100%;
	  margin: 10px 0;
	  /* padding: 10px */
	}
	.div2 > div:last-child {
	  margin-bottom: 0;
	}
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
	.div2 {
		/* display: block; */
		margin: 5px;
		padding:5px
	  }
	  .div2 > div {
		width: 50%;
		margin: 10px 0;
		/* padding: 10px; */
	  }
	  .div2 > div:last-child {
		margin-bottom: 0;
	  }
  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {
	.div2 {
		/* display: block; */
		margin: 5px;
		padding:5px
	  }
	  .div2 > div {
		width: 25%;
		margin: 10px 10px;
		padding: 10px 10px;
	  }
	  .div2 > div:last-child {
		margin-bottom: 0;
	  }
  }

.p_div {
	margin-top: 25px;
	margin-bottom: 25px;

  }

  .parag {
	font-size: 14px;
  }

  .f_div{ 
	text-align: left;
	font-size: 14px;
  }

  .l_item {
		display: grid;
		grid-template-columns: auto minmax(0px, 1fr) auto;
		gap: 5px;
		-webkit-box-align: center;
		align-items: center;
		overflow: hidden;
		margin: auto;
		font-weight: 400;
		border: none;
  }

  
  .p_div2{
	align-items: left;
	font-family: 'Roboto Slab', serif;
	font-size: 14px;
	text-align: left;
	margin-top: 20px;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 10px;
	line-height: 20px;
	text-align: justify;
  }
  li {
	list-style: none;
  }

  .w-m{
	font-size: 25px;
	margin-right: 5px;
  }

  .p_i{
	max-width: 40px;
  }

  .txt{
	font-size: 14px;
  }
  .txt p, h4, li {
	text-align: justify;
  }

  .note{
	text-align: center;
	font-size: 13px;
	line-height: 22px;
	margin: 0%;
  }
  
  .menu-toggle {
	display: none;
	background-color: #CDA44C;
	border: 0;
	color: #333333;
	border-radius: 4px;
	cursor: pointer;
  }
  
  .mobile-menu {
	display: none;
  }
  
  .mobile-menu .menu-toggle {
	display: block;
  }
  
  .mobile-menu .menu-items {
	position: absolute;
	top: 60px;
	left: 0;
	width: 100%;
	background-color: #FDF6E9;
	display: none;
	border-radius: 20px;
	margin-top: 35px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  }
  
  .mobile-menu .menu-items.open {
	display: block;
  }
  
  .mobile-menu .menu-items a {
	display: block;
	padding: 10px 20px;
	color: #2A232B;
	text-decoration: none;
  }
  
  @media (max-width: 767px) {
	.horizontal-menu {
	  display: none;
	}
  
	.mobile-menu {
	  display: block;
	}
  }

  .cookies{
	cursor: pointer;
  }

  .cookies_body {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #F5EADC;
	height: 100%;
}

.modal-content {
  max-width: 1200px;
}

.modal-dialog {
  margin: auto;
}
  
.cookies_center {
	text-align: center;
	max-width: 1200px;
	background-color: #FDFAF3;
	border-radius: 20px;
	margin-bottom: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  }

  .cookies_txt {
	font-size: 14px;
	padding-top: 10px;
  }

  .cookies_close{
	background-color: #49A6C3;
	border: 0;
	color: #ffffff;
	border-radius: 4px;
	cursor: pointer;
	margin-bottom: 10px;
	padding: 2px 5px;
  }
  .center{
	display: flex;
  	align-items: center;
	justify-content: center;
  }


  .social {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  }

 